import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/careers.module.scss'
import ItemInformativa from 'components/itemInformativa'

class InformativePrivacy extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Informative Policies Dinamiza" description={'Consulta tutte le Privacy Policies di Dinamiza.'}/>

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}>Consulta tutte le informative sulla<br />Privacy Policy<br />di <Dinamiza>Dinamiza</Dinamiza></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

                  <ItemInformativa url={'/informative/privacy-clienti'}>
                    <h2><Light>Informativa Clienti</Light></h2>
                  </ItemInformativa>

                  <ItemInformativa url={'/informative/privacy-candidati'}>
                    <h2><Light>Informativa Candidati</Light></h2>
                  </ItemInformativa>

                  <ItemInformativa url={'/informative/privacy-policy'}>
                    <h2><Light>Informativa Sito Web</Light></h2>
                  </ItemInformativa>

                  <ItemInformativa url={'/informative/cookie-policy'}>
                    <h2><Light>Cookie Policy</Light></h2>
                  </ItemInformativa>

                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default InformativePrivacy
